<template>
  <div class="box has-background-white level">
    <div class="level-left">
      <div class="level-item">
        <p class="is-size-6 has-text-centered-mobile">
          <b-icon icon="star" class="has-margin-right-50" />
          Our <strong>website experts</strong> are online and ready to help...
        </p>
      </div>
    </div>
    <div class="level-right">
      <p class="level-item">
        <a class="button is-dark is-rounded is-outlined" @click="onClick">
          <span>Create task</span>
        </a>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CreateTaskCta",
  props: {
    siteId: {
      type: String,
      required: false,
      default: null
    }
  },
  methods: {
    onClick() {
      this.$store.dispatch("tasks/openAddTaskModal", {
        userId: this.$store.getters["auth/userId"](),
        siteId: this.siteId
      });
    }
  }
};
</script>
